import axios from 'axios'
import Qs from 'qs'
import { Dialog } from 'vant'
import Tools from "@/api/Tools";
export default function request(method, url, params = {}, config = {}){
    config.headers = { token: Tools.getToken() } // { token: Tools.getCurCust() == null ? null : Tools.getCurCust().token }
    switch(method) {
        case 'get':
            return requestGet(url, params, config)
        case 'post':
            return requestPost(url, params, config)
        case 'delete':
            return requestDelete(url, params, config)
        case 'put':
            return requestPut(url, params, config)
        case 'postDatas':
            return requestPostDatas(url, params, config)
    }
}

function requestGet(url, params = {}, config = {}) {
    return new Promise((reslove, reject) => {
        config.params = params
        axios.get(url, config).then(response => {
            if (response.status === 200) {
                if (response.data.code === 100) {
                    reslove(response.data)
                } else {
                    if (params.showError != false) {
                        Dialog.alert({ title: '提示', message: response.data.msg, confirmButtonText: '知道了' })
                    }
                    reject(response)
                }
            } else {
                Dialog.alert({ title: '提示', message: response.data.msg, confirmButtonText: '知道了' })
                reject(response)
            }
        }).catch(response => {
            Dialog.alert({ title: '提示', message: response, confirmButtonText: '知道了' })
            reject(response)
        })
    })
}

function requestPost(url, data = {}, config = {}) {
    return new Promise((reslove, reject) => {
        axios.post(url, data, config).then(response => {
            var errmsg = ''
            if (response.status === 200) {
                if (response.data.code === 100) {
                    reslove(response.data)
                } else {
                    errmsg = response.data.msg
                    if (errmsg.indexOf('WxPayException') > -1) {
                        if (errmsg.indexOf('err_code_des=') > -1) {
                            errmsg = errmsg.substring(errmsg.indexOf('err_code_des=')+13)
                            errmsg = errmsg.substring(0, errmsg.indexOf(','));
                        } else if (errmsg.indexOf('errCodeDes=') > -1) {
                            errmsg = errmsg.substring(errmsg.indexOf('errCodeDes=')+11)
                            errmsg = errmsg.substring(0, errmsg.indexOf(','));
                        } else {
                            errmsg = errmsg.substring(errmsg.indexOf('returnMsg=')+10)
                            errmsg = errmsg.substring(0, errmsg.indexOf(','));
                        }
                    }
                    Dialog.alert({ title: '提示', message: errmsg, confirmButtonText: '知道了' })
                    reject(response)
                }
            } else {
                errmsg = response.data.msg
                if (errmsg.indexOf('WxPayException') > -1) {
                    if (errmsg.indexOf('err_code_des=') > -1) {
                        errmsg = errmsg.substring(errmsg.indexOf('err_code_des=')+13)
                        errmsg = errmsg.substring(0, errmsg.indexOf(','));
                    } else if (errmsg.indexOf('errCodeDes=') > -1) {
                        errmsg = errmsg.substring(errmsg.indexOf('errCodeDes=')+11)
                        errmsg = errmsg.substring(0, errmsg.indexOf(','));
                    } else {
                        errmsg = errmsg.substring(errmsg.indexOf('returnMsg=')+10)
                        errmsg = errmsg.substring(0, errmsg.indexOf(','));
                    }
                }
                Dialog.alert({ title: '提示', message: errmsg, confirmButtonText: '知道了' })
                reject(response)
            }
        }).catch(response => {
            Dialog.alert({ title: '提示', message: response, confirmButtonText: '知道了' })
            reject(response)
        })
    })
}

function requestPostDatas(url, data = {}, config = {}) {
    return new Promise((reslove, reject) => {
        axios.post(url, Qs.stringify(data), config).then(response => {
            if (response.status === 200) {
                if (response.data.code === 100) {
                    reslove(response.data)
                } else {
                    Dialog.alert({ title: '提示', message: response.data.msg, confirmButtonText: '知道了' })
                    reject(response)
                }
            } else {
                Dialog.alert({ title: '提示', message: response.data.msg, confirmButtonText: '知道了' })
                reject(response)
            }
        }).catch(response => {
            Dialog.alert({ title: '提示', message: response, confirmButtonText: '知道了' })
            reject(response)
        })
    })
}

function requestDelete(url, params = {}, config = {}) {
    return new Promise((reslove, reject) => {
        config.params = params
        axios.delete(url, config).then(response => {
            if (response.status === 200) {
                if (response.data.code === 100) {
                    reslove(response.data)
                } else {
                    Dialog.alert({ title: '提示', message: response.data.msg, confirmButtonText: '知道了' })
                    reject(response)
                }
            } else {
                Dialog.alert({ title: '提示', message: response.data.msg, confirmButtonText: '知道了' })
                reject(response)
            }
        }).catch(response => {
            Dialog.alert({ title: '提示', message: response, confirmButtonText: '知道了' })
            reject(response)
        })
    })
}

function requestPut(url, data = {}, config = {}) {
    return new Promise((reslove, reject) => {
        axios.put(url, data, config).then(response => {
            if (response.status === 200) {
                if (response.data.code === 100) {
                    reslove(response.data)
                } else {
                    Dialog.alert({ title: '提示', message: response.data.msg, confirmButtonText: '知道了' })
                    reject(response)
                }
            } else {
                Dialog.alert({ title: '提示', message: response.data.msg, confirmButtonText: '知道了' })
                reject(response)
            }
        }).catch(response => {
            Dialog.alert({ title: '提示', message: response, confirmButtonText: '知道了' })
            reject(response)
        })
    })
}
